import React from "react";

import { DotStyle, DotTitle, YearTitle } from "./slide_styles";

const Slide = props => {
  const { content, id, onClick, width } = props;
  const title = content.event_title[0].text;
  const year = content.event_date[0].text.split(" ")[1];
  const showYear = content.showYear;
  return (
    <span style={{ width: `${width / 3}px` }}>
      <div style={{ width: "100vw" }} onClick={() => onClick(id)}>
        <YearTitle showYear={showYear}>{year}</YearTitle>
        <DotStyle active={props.active} />
        <DotTitle>{title}</DotTitle>
      </div>
    </span>
  );
};

export default Slide;
