import styled from "styled-components";

import { SPh1 } from "../base_components/typography";
import { grey } from "../base_components/colors";

export const TimelineTitle = styled(SPh1)`
    color: ${grey};
    text-align: center;
`;

export const TimelineContainer = styled.div`
    margin-top: 100px;
    margin-bottom: 100px;
`;
