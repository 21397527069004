import styled from "styled-components";

import { orange, grey, white } from "../../base_components/colors";
import { SPsh1 } from "../../base_components/typography";

import { sizes } from "../../base_components/screenSizes";

export const DotStyle = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid ${orange};
  background: ${props => (props.active ? `${orange}` : "white")};
  box-shadow: ${props =>
    props.active ? `inset 0 0 0 2px ${white}` : "initial"};
  cursor: pointer;

  :hover {
    box-shadow: inset 0 0 0 2px ${orange};
  }
`;

export const DotTitle = styled(SPsh1)`
  color: ${grey};
  position: absolute;
  width: 100%;

  @media (max-width: ${sizes.large}px) {
    font-size: 18px;
  }

  @media (max-width: ${sizes.medium}px) {
    display: none;
  }
`;

export const YearTitle = styled(SPsh1)`
  color: ${grey};
  position: absolute;
  top: -40px;
  display: ${props => (props.showYear ? "initial" : "none")};
`;
