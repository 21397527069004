import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";

import {
  TitleStyle,
  MemberCol,
  MemberRow,
  MeetTheTeamContainer,
  ItemContainer,
  ModalRow,
  ModalCol,
  NameStyle,
  PositionStyle,
  ModalColImg,
  CircleImageHolder,
  BioStyle,
} from "./meet_the_team_styles";
import MeetTheTeamCard from "./meet_the_team/meet_the_team_card";
import { RichText } from "prismic-reactjs-custom";
import Modal from "../base_components/modal/modal";

const Item = props => {
  const { modalData } = props;
  const isEmpty = Object.keys(modalData).length === 0;
  const name = !isEmpty ? modalData.team_member_name[0].text : "";
  const position = !isEmpty ? modalData.team_member_position[0].text : "";
  const portraitSrc = !isEmpty ? modalData.team_member_photo.url : "";
  const portraitAlt = !isEmpty ? modalData.team_member_photo.alt : "";
  const bio = !isEmpty ? modalData.team_member_bio : [];

  return (
    <ItemContainer>
      <ModalRow reverse>
        <ModalCol size={2} breakSize={"small"}>
          <NameStyle>{name}</NameStyle>
          <PositionStyle>{position}</PositionStyle>
        </ModalCol>
        <ModalColImg size={1} breakSize={"small"}>
          <CircleImageHolder>
            <img src={portraitSrc} alt={portraitAlt} />
          </CircleImageHolder>
        </ModalColImg>
      </ModalRow>

      <ModalRow>
        <RichText richText={bio} paragraph={BioStyle} />
      </ModalRow>
    </ItemContainer>
  );
};

const MeetTheTeam = props => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  const onMemberClick = data => {
    setModalVisible(true);
    setModalData(data);
  };

  const { data, id } = props;
  const title =
    data.prismic.allAboutpages.edges[0].node.meet_the_team_title[0].text;

  const members = data.prismic.allAboutpages.edges[0].node.meet_the_team;
  const content = <Item modalData={modalData} />;

  return (
    <MeetTheTeamContainer id={id} role="region" aria-labelledby={id}>
      <Modal
        isModalVisible={isModalVisible}
        closeModal={setModalVisible}
        content={content}
      />
      <TitleStyle> {title} </TitleStyle>
      <MemberRow>
        {members.map((item, index) => {
          return (
            <MemberCol>
              <MeetTheTeamCard content={item} onClick={onMemberClick} />
            </MemberCol>
          );
        })}
      </MemberRow>
    </MeetTheTeamContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query MeetTheTeamQuery {
        prismic {
          allAboutpages {
            edges {
              node {
                meet_the_team_title
                meet_the_team {
                  team_member_bio
                  team_member_name
                  team_member_photo
                  team_member_position
                }
              }
            }
          }
        }
      }
    `}
    render={data => <MeetTheTeam data={data} {...props} />}
  />
);
