import styled from "styled-components";

import { Col, Row } from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { darkBlue, white } from "../base_components/colors";
import { SPsh1 } from "../base_components/typography";

export const DetailStyle = styled(SPsh1)`
    color: ${white};
`;

export const DetailContainer = styled.div`
    text-align: left;
    padding: 40px;

    @media(max-width: ${sizes.large}px) {
        text-align: center;
    }
`;

export const HeroImg = styled.img`
    width: 80%;
    border-radius: 10px;
`;


export const HeroContainer = styled.div`
    background-color: ${darkBlue};
    color: ${white};
    overflow: hidden;
    position: relative;
    display: flex;
    height: fit-content;
    min-height: 90vh;
    z-index: 1;
    padding-top: 80px;
    padding-bottom: 80px;
    @media(max-width: ${sizes.large}px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
     @media(max-width: ${sizes.medium}px) {
        height: auto;
        min-height: inherit;
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;

export const HeroCol = styled(Col)`
    position: relative;

`;

export const HeroImgCol = styled(HeroCol)`
    display: flex;
    justify-content: center;
`;

export const HeroRow = styled(Row)`
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    margin-top: 60px;
    padding-top: 50px;
    padding-bottom: 50px;



`;