import React, { useEffect, useRef } from "react";
import { RichText } from "prismic-reactjs-custom";

import {
  EventDetailContainer,
  EventImgContainer,
  EventCol,
  EventRow,
  EventImg,
  DateStyle,
  TitleStyle,
  DetailStyle,
} from "./timeline_event_styles";

const TimelineEvent = React.forwardRef((props, ref) => {
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current = handleScroll;
  });

  useEffect(() => {
    const scroll = () => {
      scrollRef.current();
    };

    const onScroll = window.addEventListener("scroll", scroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const handleScroll = () => {
    if (ref.current != null) {
      let topViewPort = window.pageYOffset;
      let refTop = ref.current.offsetTop;
      // this offset amount is equal to the height of the timeline bar header thing
      let offsetAmount = 150;
      let offset = refTop - offsetAmount;

      let bottom = offset + ref.current.offsetHeight;

      if (topViewPort >= offset && topViewPort <= bottom) {
        props.onChange(props.id);
      }
    }
  };

  const { content } = props;
  const title = content.event_title[0].text;
  const date = content.event_date[0].text;
  const detail = content.event_details;
  const imgSrc = content.event_image.url;
  const imgAlt = content.event_image.alt;

  return (
    <EventRow ref={ref}>
      <EventCol size={1} breakSize={"medium"}>
        <EventImgContainer>
          <EventImg src={imgSrc} alt={imgAlt}/>
        </EventImgContainer>
      </EventCol>

      <EventCol size={1} breakSize={"medium"}>
        <EventDetailContainer>
          <DateStyle>{date}</DateStyle>
          <TitleStyle>{title}</TitleStyle>
          <RichText richText={detail} paragraph={DetailStyle} />
        </EventDetailContainer>
      </EventCol>
    </EventRow>
  );
});

export default TimelineEvent;
