import styled from "styled-components";
import { orange } from "../../base_components/colors";

export const SliderStyle = styled.div`
  position: sticky;
  top: 0;
  height: 150px;
  width: ${props => props.width}px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

export const SliderHideStyle = styled.div`
  width: calc(${props => props.width}px - 60px);
  margin-left: 30px;
  overflow: hidden;
  height: 150px;
  display: flex;
  align-items: center;
`;

// TODO: 25w is for the spacing
export const SliderContentStyle = styled.div`
  transform: translateX(calc(${props => props.translate}px + 25vw));
  transition: transform ease-out ${props => props.transition}s;
  height: auto;
  width: ${props => props.width}px;
  display: flex;
`;

export const Line = styled.div`
  position: absolute;
  width: calc(${props => props.width}px - 60px);
  left: 25px;
  border: 3px solid ${orange};
`;
