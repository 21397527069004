import React, { createRef, useState } from "react";
import { StaticQuery, graphql } from "gatsby";

import { TimelineTitle, TimelineContainer } from "./timeline_styles";

import Slider from "./slider/slider";
import TimelineEvent from "./timeline_events/timeline_event";

const splitDates = events => {
  var year = "";
  for (let i = 0; i < events.length; i++) {
    let event = events[i];
    let currYear = event.event_date[0].text.split(" ")[1];
    if (year !== currYear) {
      event["showYear"] = true;
      year = currYear;
    } else {
      event["showYear"] = false;
    }
  }
  return events;
};

const Timeline = props => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, id } = props;
  const title = data.prismic.allAboutpages.edges[0].node.timeline_ttile[0].text;
  let events = data.prismic.allAboutpages.edges[0].node.timeline_event;
  const cleanedDates = splitDates(events);

  const refs = cleanedDates.reduce((acc, value, index) => {
    acc[index] = createRef();
    return acc;
  }, {});

  const handleChange = id => {
    let refTop = refs[id].current.offsetTop;
    // this offset amount is equal to the height of the timeline bar header thing
    let offsetAmount = 150;
    let offset = refTop - offsetAmount;

    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const handleChangeActive = id => {
    setActiveIndex(id);
  };

  return (
    <TimelineContainer id={id} role="region" aria-labelledby={id}>
      <TimelineTitle>{title}</TimelineTitle>
      <Slider
        slides={cleanedDates}
        onClick={handleChange}
        activeIndex={activeIndex}
      />

      {cleanedDates.map((item, index) => {
        return (
          <TimelineEvent
            id={index}
            content={item}
            ref={refs[index]}
            onChange={handleChangeActive}
          />
        );
      })}
    </TimelineContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query TimelineQuery {
        prismic {
          allAboutpages {
            edges {
              node {
                timeline_ttile
                timeline_event {
                  event_image
                  event_title
                  event_date
                  event_details
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Timeline data={data} {...props} />}
  />
);
