import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { SPh1 } from "../base_components/typography";

import { DetailContainer, HeroImg, HeroCol, HeroImgCol, HeroRow, HeroContainer, DetailStyle } from "./hero_styles";

import BackgroundPattern from "../base_components/background";


const Hero = (props) =>  {

    const { data, id } = props;
    
    const title = data.prismic.allAboutpages.edges[0].node.why_we_started[0].text;
    const detail = data.prismic.allAboutpages.edges[0].node.why_we_started_content[0].text;
    const url = data.prismic.allAboutpages.edges[0].node.why_we_started_image.url;
    const alt = data.prismic.allAboutpages.edges[0].node.why_we_started_image.alt;


    return(
        <HeroContainer id={id}>
                <BackgroundPattern strokeColor={"pillBackgroundGreen"} fillColor={"pillBackgroundGreen"} left={-100} top={-100} zindex={-1}/>
                <BackgroundPattern strokeColor={"pillBackgroundGreen"} fillColor={"pillBackgroundGreen"} right={-50} bottom={-100} zindex={-1}/>

            <HeroRow>

                <HeroCol size={1} breakSize="large">
                  <DetailContainer>
                    <SPh1>{title}</SPh1>
                    <DetailStyle>{detail}</DetailStyle>
                  </DetailContainer>
                </HeroCol>

                <HeroImgCol size={1} breakSize="large">
                  <HeroImg src={url} alt={alt}/>
                </HeroImgCol>


            </HeroRow>


          </HeroContainer>

    ) 

}



export default props => (
  <StaticQuery
    query={graphql`
        query WhatIsSmartyPillQuery {
          prismic {
    allAboutpages {
      edges {
        node {
          why_we_started
          why_we_started_content
          why_we_started_image
        }
      }
    }
  }
        }
    `}
    render={data => <Hero data={data} {...props} />}
  />
)
