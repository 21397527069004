import styled from "styled-components";

import { mediumBlue, white, yellow, grey } from "../../base_components/colors";
import { SPsh1, SPbody1 } from "../../base_components/typography";

import { sizes } from "../../base_components/screenSizes";

export const MemberCard = styled.div`
  background: ${white};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: ${props =>
    props.isHover ? `2px solid ${mediumBlue}` : `2px solid ${white}`};
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ContentHolder = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CircleImageHolder = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  border: ${props =>
    props.isHover ? `4px solid ${white}` : `4px solid ${yellow}`};

  @media (max-width: ${sizes.large}px) {
    width: 175px;
    height: 175px;
  }

  @media (max-width: ${sizes.small}px) {
    width: 125px;
    height: 125px;
  }

  img {
    width: 100%;
    height: auto;
  }

  div,
  p {
    display: ${props => (props.isHover ? `initial` : `none`)};
  }
`;

export const SeeMoreHolder = styled.div`
  background: ${mediumBlue};
  opacity: 0.6;
  position: absolute;
  top: 0;
  height: 300px;
  width: 300px;
`;

export const SeeMoreTextOverlay = styled(SPsh1)`
  color: ${white};
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`;

export const NameStyle = styled(SPsh1)`
  color: ${grey};
  margin-top: 16px;
  text-align: center;
`;

export const PositionStyle = styled(SPbody1)`
  color: ${grey};
  text-align: center;
`;
