import styled from "styled-components";

import { SPh1 } from "../base_components/typography";
import { grey, lightBlue, yellow } from "../base_components/colors";
import { sizes } from "../base_components/screenSizes";
import { SectionContainer } from "../base_components/layout";
import { SPsh1, SPh2, SPbody1 } from "../base_components/typography";
import { Col, Row } from "../base_components/layout";

export const TitleStyle = styled(SPh1)`
  color: ${grey};
  text-align: center;
`;

export const MemberCol = styled.div`
  margin-bottom: 10px;
  flex-basis: 25%;

  @media (max-width: ${sizes.xl}px) {
    flex-basis: 50%;
    flex-grow: 1;
  }
`;

export const ItemContainer = styled.div`
  width: 100%;
`;

export const MemberRow = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${sizes.xl}px) {
    flex-wrap: wrap;
  }
`;

export const MeetTheTeamContainer = styled(SectionContainer)`
  background: ${lightBlue};
  position: inherit;
`;

export const NameStyle = styled(SPh2)`
  color: ${grey};
  margin-top: 8px;
  @media (max-width: ${sizes.small}px) {
    text-align: center;
  }
`;

export const PositionStyle = styled(SPsh1)`
  color: ${grey};
  margin-top: 8px;
  text-align: left;
  @media (max-width: ${sizes.small}px) {
    text-align: center;
  }
`;

export const CircleImageHolder = styled.div`
  position: relative;
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid ${yellow};

  img {
    width: 100%;
    height: auto;
  }
`;

export const ModalCol = styled(Col)``;

export const ModalColImg = styled(Col)`
  display: flex;
  justify-content: center;
`;

export const ModalRow = styled(Row)`
  align-items: center;
  flex-direction: row;

  @media (max-width: ${sizes.small}px) {
    flex-direction: ${props => (props.reverse ? `column-reverse` : `initial`)};
  }
`;

export const BioStyle = styled(SPbody1)`
  color: ${grey};
  margin-top: 8px;
`;
