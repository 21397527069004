import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";

import StayConnectedFrom from "../base_components/stay_connected_form";
import EmailPopUp from "../base_components/email_pop_up";
import Modal from "../base_components/modal/modal";
import BackgroundPattern from "../base_components/background";

import {
  JoinUsContainer,
  TitleStyle,
  MessageStyle,
  BackgroundContainer,
  ContentStyle
} from "./join_us_styles";

const JoinUs = props => {
  const { data } = props;
  const title = data.prismic.allAboutpages.edges[0].node.join_us_title[0].text;
  const message =
    data.prismic.allAboutpages.edges[0].node.join_us_message[0].text;

  const [isModalVisible, setModalVisible] = useState(false);

  const content = <EmailPopUp isThankYou={true} />;

  return (
    <JoinUsContainer>
      <Modal
        isModalVisible={isModalVisible}
        closeModal={setModalVisible}
        onJoin={setModalVisible}
        content={content}
        isNotification={true}
      />
            <BackgroundContainer>
        <BackgroundPattern
          strokeColor={"pillBackgroundGreenDark"}
          fillColor={"pillBackgroundGreenDark"}
          left={-200}
          bottom={10}
          zindex={-1}
        />
        <BackgroundPattern
          strokeColor={"pillBackgroundGreenDark"}
          fillColor={"pillBackgroundGreenDark"}
          right={-200}
          bottom={10}
          zindex={-1}
        />
      </BackgroundContainer>
      <ContentStyle>
      <TitleStyle>{title}</TitleStyle>
      <MessageStyle>{message}</MessageStyle>
      <StayConnectedFrom onJoin={setModalVisible} />
      </ContentStyle>

    </JoinUsContainer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query JoinUsQuery {
        prismic {
          allAboutpages {
            edges {
              node {
                join_us_title
                join_us_message
              }
            }
          }
        }
      }
    `}
    render={data => <JoinUs data={data} {...props} />}
  />
);
