import React, { useState } from "react";

import {
  MemberCard,
  CircleImageHolder,
  SeeMoreHolder,
  SeeMoreTextOverlay,
  NameStyle,
  PositionStyle,
  ContentHolder,
} from "./meet_the_team_card_styles";

const MeetTheTeamCard = props => {
  const [isHover, setIsHover] = useState(false);

  const { content } = props;
  const name = content.team_member_name[0].text;
  const position = content.team_member_position[0].text;
  const portraitSrc = content.team_member_photo.url;
   const portraitAlt = content.team_member_photo.alt;

  return (
    <MemberCard
      isHover={isHover}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => props.onClick(content)}
    >
      <ContentHolder>
        <CircleImageHolder isHover={isHover}>
          <SeeMoreHolder />
          <SeeMoreTextOverlay>Read Bio</SeeMoreTextOverlay>
          <img src={portraitSrc} alt={portraitAlt} />
        </CircleImageHolder>
        <NameStyle>{name}</NameStyle>
        <PositionStyle>{position}</PositionStyle>
      </ContentHolder>
    </MemberCard>
  );
};

export default MeetTheTeamCard;
