import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import SEO from "../components/seo";

import Header from "../components/navbar/header";
import Hero from "../components/about/hero";

import Timeline from "../components/about/timeline";
import JoinUs from "../components/about/join_us";
import MeetTheTeam from "../components/about/meet_the_team";
import Stayconnected from "../components/footer/stayconnected";

const AboutPage = () => (
  <ParallaxProvider>
    <div>
      <SEO title="About" />
      <Header />
      <Hero id="why_we_started" />
      <Timeline id="timeline" />
      <JoinUs />
      <MeetTheTeam id={"our_team"} />
      <Stayconnected />
    </div>
  </ParallaxProvider>
);

export default AboutPage;
