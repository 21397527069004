import styled from "styled-components";

import { SPh1, SPsh1 } from "../base_components/typography";
import { white, mediumBlue } from "../base_components/colors";
import { sizes } from "../base_components/screenSizes";
import { SectionContainer } from "../base_components/layout";

export const TitleStyle = styled(SPh1)`
  color: ${white};
  text-align: center;
  margin-bottom: 20px;
`;

export const MessageStyle = styled(SPsh1)`
  color: ${white};
  text-align: center;
  width: 50%;
  @media(max-width: ${sizes.medium}px) {
     width: 100%;
  }
`;

export const ContentStyle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const MemberCol = styled.div`
  margin-bottom: 10px;
  flex-basis: 25%;

  @media (max-width: ${sizes.large}px) {
    flex-basis: 50%;
  }
`;

export const MemberRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
`;

export const JoinUsContainer = styled(SectionContainer)`
  background: ${mediumBlue};
  overflow: visible;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
    height: 300px;
    overflow: hidden;
    width: 100vw;

    margin-left: -80px;
      margin-top: -40px;
z-index: -1;

    @media(max-width: ${sizes.large}px) {
      margin-top: -10px;
      margin-left: -20px;
}

@media(max-width: ${sizes.medium}px) {
      margin-left: -20px;
  }
`;
