import React, { useState, useRef, useEffect } from "react";

import {
  SliderStyle,
  SliderContentStyle,
  Line,
  SliderHideStyle,
} from "./slider_styles";
import Slide from "./slide";
import Arrow from "./arrow";

const Slider = props => {
  const resizeRef = useRef();

  useEffect(() => {
    resizeRef.current = handleResize;
  });

  useEffect(() => {
    const resize = () => {
      resizeRef.current();
    };

    setTimeout(function() {
      handleResize();
    }, 500);

    const onResize = window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const handleResize = () => {
    setState({ ...state, width: 0 });
    let newWidth = getWidth();
    setState({ ...state, width: newWidth });
  };

  const getWidth = () => {
    if (typeof window !== `undefined`) {
      return window.innerWidth;
    }
    return 1440;
  };

  const onChange = id => {
    props.onClick(id);
    setState({
      ...state,
      activeIndex: id,
    });
  };

  const [state, setState] = useState({
    activeIndex: props.activeIndex,
    transition: 0.45,
    width: getWidth(),
  });

  const { transition, activeIndex, width } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      props.onClick(0);
      return setState({
        ...state,
        activeIndex: 0,
      });
    }
    props.onClick(activeIndex + 1);
    setState({
      ...state,
      activeIndex: activeIndex + 1,
    });
  };

  const prevSlide = () => {
    if (activeIndex === 0) {
      props.onClick(props.slides.length - 1);
      return setState({
        ...state,
        activeIndex: props.slides.length - 1,
      });
    }
    props.onClick(activeIndex - 1);
    setState({
      ...state,
      activeIndex: activeIndex - 1,
    });
  };

  const translate = props.activeIndex * (width / 3) * -1;
  const isLeftArrowVisible = props.activeIndex !== 0;
  const isRightArrowVisible = props.activeIndex !== props.slides.length - 1;

  return (
    <SliderStyle width={width}>
      <Line width={width} />
      <SliderHideStyle width={width}>
        <SliderContentStyle
          translate={translate}
          transition={transition}
          width={width}
          quarter={width / 4}
        >
          {props.slides.map((item, index) => {
            return (
              <Slide
                id={index}
                active={index === props.activeIndex}
                content={item}
                onClick={onChange}
                width={width}
              />
            );
          })}
        </SliderContentStyle>
      </SliderHideStyle>
      <Arrow
        direction="left"
        handleClick={prevSlide}
        isVisible={isLeftArrowVisible}
      />
      <Arrow
        direction="right"
        handleClick={nextSlide}
        isVisible={isRightArrowVisible}
      />
    </SliderStyle>
  );
};

export default Slider;
